/* Initialisation du slider */
(function () {

    var sliders = document.querySelectorAll('.bloc-relation-scrolly .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }


            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });

        // Actualise la position d'un indicateur sur l'onglet courant
        var containerOnglets = parent.querySelectorAll('.bloc-relation .container-onglets');
        for (var i = 0; i != containerOnglets.length; i++) {
            var container = containerOnglets[i];
            initContainerOnglets(container);
        }


        function initContainerOnglets(container) {
            var onglets = container.querySelectorAll('label');

            for (var i = 0; i != onglets.length; i++) {
                var onglet = onglets[i];
                onglet.addEventListener('click', onClickOnglet);
            }

            function onClickOnglet(e) {
                e.preventDefault();
                var target = e.currentTarget;

                // empèche le scroll en haut de page au click sur le label
                var radioId = target.getAttribute('for');
                var radio = parent.querySelector('#' + radioId);
                radio.click();
            }
        }
    }

})();
