var thSliderTools = {

    onInitNav: function (slider, navButtons) {
        navButtons[0].addEventListener('click', function () {
            slider.prev();
        });
        navButtons[1].addEventListener('click', function () {
            slider.next();
        });
    },
    onInitNavRGAA: function (slider, navButtons) {

        var determineItemsVisibles = function () {

            var containerLeft = slider.el.parentNode.getBoundingClientRect().left - 5;
            var containerRight = slider.el.parentNode.getBoundingClientRect().right + 5;
            var regexpCliquable = new RegExp('(a|button|input|select)', 'i');

            for (var i = 0; i < slider.state.count; i++) {
                if (slider.state.items[i].getBoundingClientRect().left >= containerLeft && slider.state.items[i].getBoundingClientRect().right <= containerRight) {
                    // visible
                    slider.state.items[i].removeAttribute('aria-hidden');
                    if (slider.state.items[i].tagName.match(regexpCliquable)) {
                        // element du slide
                        slider.state.items[i].setAttribute('tabindex', 0);
                    } else {
                        // element interne
                        var link = slider.state.items[i].querySelector('a');
                        if (link) {
                            link.setAttribute('tabindex', 0);
                        }
                    }
                } else {
                    // non visible
                    slider.state.items[i].setAttribute('aria-hidden', true);
                    if (slider.state.items[i].tagName.match(regexpCliquable)) {
                        // element du slide
                        slider.state.items[i].setAttribute('tabindex', -1);
                    } else {
                        // element interne
                        var link = slider.state.items[i].querySelector('a');
                        if (link) {
                            link.setAttribute('tabindex', -1);
                        }
                    }
                }
            }
        }

        determineItemsVisibles();

        navButtons[0].addEventListener('click', function () {
            slider.prev();
            setTimeout(determineItemsVisibles, 200);
            setTimeout(determineItemsVisibles, 400);
        });

        navButtons[1].addEventListener('click', function () {
            slider.next();
            setTimeout(determineItemsVisibles, 200);
            setTimeout(determineItemsVisibles, 400);
        });
    },

    determineItemsVisiblesRGAA: function (slider) {

        var containerLeft = slider.el.parentNode.getBoundingClientRect().left - 5;
        var containerRight = slider.el.parentNode.getBoundingClientRect().right + 5;

        for (var i = 0; i < slider.state.count; i++) {
            var bounding = slider.state.items[i].getBoundingClientRect();

            var isVisible = false;
            if (bounding.left >= containerLeft && bounding.right <= containerRight) {
                isVisible = true;
            }

            thSliderTools.setItemVisibleOrNotRGAA(slider.state.items[i], isVisible);
        }
    },
    setItemVisibleOrNotRGAA: function (item, isVisible) {

        if (isVisible) {
            // element interne
            var links = item.querySelectorAll('a');


            for (var i = 0; i < links.length; i++) {
                links[i].setAttribute('tabindex', 0);
            }


        } else {
            // element interne
            var links = item.querySelectorAll('a');

            for (var i = 0; i < links.length; i++) {
                links[i].setAttribute('tabindex', -1);
            }


        }

    },

    onInitDots: function (slider, dotsWrapper, contentCallback) {
        if (dotsWrapper) {
            slider.lastDotActive = null;

            for (var i = 0; i < slider.state.count; i++) {
                thSliderTools._addButton(dotsWrapper, slider, i, contentCallback);
            }
        }
    },

    onInitCounter: function (slider, counterWrapper) {
        if (counterWrapper) {
            var currentEl = counterWrapper.querySelector('.current');
            var totalEl = counterWrapper.querySelector('.total');

            if (currentEl && totalEl) {
                totalEl.innerHTML = slider.state.count;
                currentEl.innerHTML = 1;
            }
        }
    },
    // Note: la fonction onInitTextNav à été ajoutée parce qu'on a dans le bloc slider thématique une série de boutons : au click sur un bouton on doit aller vers la slide corréspondante.
    // En cablâge on donne à chaque bouton un attribut data-index numérique : ici on reprend le petit numéro et on l'utilise pour faire un slider.goto(numeroslide)
    // Le if / else sert à gérer le cas où le client a crée beaucoup de boutons en BO alors qu'on a moins de slides (ex: 17 liens mais 5 slides). On va donc supprimer les liens en plus vu qu'ils
    // ne sont pas utilisés

    onInitTextNav: function (slider, textNav, totalSlidesNumber) {
        if (textNav.length > 0) {

            for (var i = 0; i < textNav.length; i++) {
                if (i < totalSlidesNumber) {
                    textNav[i].addEventListener('click', function (e) {
                        var buttonIndex = e.target.getAttribute("data-index");
                        e.preventDefault();
                        slider.goto(buttonIndex);

                    });
                } else {
                    console.log("DEBUG : Les éléments suivants ont été supprimés de la navigation textuelle parce qu'ils ne correspondent pas à une slide :", textNav[i].outerHTML)
                    textNav[i].remove();
                }
            }
        }
    },

    onChangeNav: function (slider, navButtons) {

        // si le slider ne peut pas faire un prev alors on disabled le bouton prev, sinon on supprime disabled
        if (!slider.canPrev()) {
            navButtons[0].setAttribute('disabled', 'disabled');
            navButtons[0].setAttribute('aria-disabled', true);
        } else {
            navButtons[0].removeAttribute('disabled');
            navButtons[0].setAttribute('aria-disabled', false);
        }

        // si le slider ne peut pas faire un next alors on disabled le bouton next
        if (!slider.canNext()) {
            navButtons[1].setAttribute('disabled', 'disabled');
            navButtons[1].setAttribute('aria-disabled', 'true');
        } else {

            // si le slider peut faire un next mais que le dernier element est entièrement visible dans la zone du slider, alors on disabled le bouton next, dans le cas contraire, on supprime disabled
            if (slider.lastItemIn()) {
                navButtons[1].removeAttribute('disabled');
                navButtons[1].setAttribute('aria-disabled', 'false');
            } else {
                navButtons[1].setAttribute('disabled', 'disabled');
                navButtons[1].setAttribute('aria-disabled', 'true');
            }
        }
    },

    onChangeTextNav: function (slider, textNav) {

        // Même comportement que dans le onChangeDots
        if (textNav.length > 0) {
            if (slider.lastDotActive) {
                slider.lastDotActive.className = '';
                slider.lastDotActive.setAttribute('aria-selected', 'false');
            }
            if (textNav[0].childNodes.length > 0) {

                slider.lastDotActive = textNav[slider.state.current];
                slider.lastDotActive.className = 'active';
                slider.lastDotActive.setAttribute('aria-selected', 'true');
                slider.lastDotActive.focus();
            }
        }
    },
    onChangeNavIgnoreLastItemIn: function (slider, navButtons) {

        // si le slider ne peut pas faire un prev alors on disabled le bouton prev, sinon on supprime disabled
        if (!slider.canPrev()) {
            navButtons[0].setAttribute('disabled', 'disabled');
            navButtons[0].setAttribute('aria-disabled', 'true');
        } else {
            navButtons[0].removeAttribute('disabled');
            navButtons[0].setAttribute('aria-disabled', 'false');
        }

        // si le slider ne peut pas faire un next alors on disabled le bouton next
        if (!slider.canNext()) {
            navButtons[1].setAttribute('disabled', 'disabled');
            navButtons[1].setAttribute('aria-disabled', 'true');
        } else {
            navButtons[1].removeAttribute('disabled');
            navButtons[1].setAttribute('aria-disabled', 'false');
        }
    },

    onChangeDots: function (slider, dotsWrapper) {

        // masque le dot actuellement active et met le active sur le bon
        if (dotsWrapper) {
            if (slider.lastDotActive) {
                slider.lastDotActive.className = '';
                slider.lastDotActive.setAttribute('aria-selected', 'false');
            }
            if (dotsWrapper.children.length > 0) {
                if (slider.isMobile() && (slider.el.getBoundingClientRect().width + slider.el.scrollLeft) >= slider.el.scrollWidth) {
                    slider.lastDotActive = dotsWrapper.children[slider.state.count - 1];
                    slider.lastDotActive.className = 'active';
                    slider.lastDotActive.setAttribute('aria-selected', 'true');
                } else {
                    slider.lastDotActive = dotsWrapper.children[slider.state.current];
                    slider.lastDotActive.className = 'active';
                    slider.lastDotActive.setAttribute('aria-selected', 'true');
                    // slider.lastDotActive.focus();
                }
            }
        }
    },

    onChangeCounter: function (slider, counterWrapper) {

        // masque le dot actuellement active et met le active sur le bon
        if (counterWrapper) {
            var currentEl = counterWrapper.querySelector('.current');
            if (currentEl) {
                if (slider.isMobile() && (slider.el.getBoundingClientRect().width + slider.el.scrollLeft) >= slider.el.scrollWidth) {
                    currentEl.innerHTML = slider.state.count;
                } else {
                    currentEl.innerHTML = slider.state.current + 1;
                }
            }
        }
    },

    _addButton: function (wrapper, slider, index, contentCallback) {
        var button = document.createElement('button');

        if (contentCallback) {
            button = contentCallback(button, index);
        }
        button.addEventListener('click', function (e) {
            e.preventDefault();
            slider.goto(index);
        });
        wrapper.appendChild(button);
    },


    afterImgLoad: function (el, callback) {

        var loaded = 0;
        var imgtoLoad = 0;

        var imgs = el.querySelectorAll('img');

        imgtoLoad = imgs.length;

        for (var i = 0; i < imgtoLoad; i++) {
            if (imgs[i].complete) {
                loaded++;
            } else {
                imgs[i].onload = function () {
                    loaded++;
                    if (loaded == imgtoLoad) {
                        setTimeout(function () {
                            callback();
                        }, 50);
                    }
                }
                imgs[i].onerror = function () {
                    loaded++;
                    if (loaded == imgtoLoad) {
                        setTimeout(function () {
                            callback();
                        }, 50);
                    }
                }
            }
        }

        if (loaded == imgtoLoad) {
            setTimeout(function () {
                callback();
            }, 50);
        }
    }

};
