var calendarDateFormat = 'DD/MM/YYYY';
var calendarDateLanguage = $('html').data('lang');

/** Calendrier Formidable de base  */
const dateRangePickerInit = (cela) => {
    if(!isTouchDevice()){
        $(cela).attr('type','text');
        $(cela).attr('placeholder','');
        $(cela).dateRangePicker({
            inline: false,
            singleDate : true,
            alwaysOpen: false,
            singleMonth: true,
            showShortcuts: false,
            showTopbar: false,
            monthSelect: true,
            yearSelect: [2020, 2030],
            startOfWeek: 'monday',
            customArrowPrevSymbol: '<span class="icon-arrow-left"></span>',
            customArrowNextSymbol: '<span class="icon-arrow-right"></span>',
            format: calendarDateFormat,
            language: calendarDateLanguage,
            setValue: function (s, s1, s2, inst) {

                $(cela).parent().addClass("has-content");
                $(cela).val(s1);
                if (!$(cela).hasClass('no-refresh')) {
                    $(cela).trigger('force-change');
                }
            },
        });
    }else{
        $(cela).attr('type','date');
    }
};

/** Calendrier Formidable de base  */
$('.bloc-form input.frm_date').each(function(){
    dateRangePickerInit(this);
});