var maree = {

    intervalList: [],

    clear: function () {
        $.each(maree.intervalList, function (k, interval) {
            clearInterval(interval);
        })
        maree.intervalList = [];
    },

    init: function () {

        maree.clear();

        $('[data-maree]').each(function () {

            var _self = this;

            var now = new Date();

            var month = now.getMonth() + 1;
            var day = now.getDate();
            if (month <= 9) {
                month = '0' + month;
            }
            if (day <= 9) {
                day = '0' + day;
            }
            var year = now.getFullYear();
            var hour = now.getHours();
            var min = now.getMinutes();
            var date = year + '-' + month + '-' + day + ' ' + hour + ':' + min;

            maree.get(['now', date], function (data) {
                if (data) {
                    maree.displayData(_self, data);

                    maree.intervalList.push(setInterval(function () {
                        maree.displayData(_self, data);
                    }, 60000));
                }
            });

        });
    },

    get: function (data, callback) {
        $.get(ajax_url, {
            action: "get_data_maree",
            type: data[0],
            date: data[1]
        }, function (result) {
            if (result.success == 1) {
                callback(result.data);
            }
        }, 'json');
    },

    getFromTo: function (data, callback) {
        $.get(document.location.href, {type: data[0], from: data[1], to: data[2]}, function (result) {
            callback(result);
        });
    },


    displayData: function (el, data) {

        var data_maree = data['next_maree'];

        data_maree['dateTime'] = data_maree['dateTime'].replace(/-/g, '/');

        var nextTime = new Date(data_maree['dateTime']);

        var currData = data_maree;

        // si on veut un décompte jusqu'à la prochaine info
        // var time = (nextTime.getTime()) - (new Date().getTime());
        //
        // var hour = Math.floor(time / 3600000);
        // time -= hour * 3600000;
        // var min = Math.ceil(time / 60000);
        // if (min <= 9) {
        //     min = '0' + min;
        // }
        // // console.log(min);
        // if (min == 60) {
        //     hour++;
        //     min = 0;
        // }
        // // if(hour <= 9){
        // //     hour = '0'+hour;
        // // }

        // si on veut l'heure de la prochaine
        var hour = nextTime.getHours();
        var min = nextTime.getMinutes();
        var text = data['trad'][currData['state']];

        $('.js-component-maree-text', el).text(text);
        $('.js-component-maree-time', el).html(hour + ':' + min);

        if (currData['coef']) {
            $('.js-component-maree-coef.hide', el).removeClass('hide');
            $('.js-component-maree-coef span', el).html(currData['coef']);
        } else {
            $('.js-component-maree-coef:not(.hide)', el).addClass('hide');
        }

        if (currData['state'] == 'low') {
            $('.js-component-maree-status', el).addClass('icon-arrow-down');
            $('.icon-maree', el).addClass('icon-maree-basse');
        } else if (currData['state'] == 'high') {
            $('.js-component-maree-status', el).addClass('icon-arrow-up');
            $('.icon-maree', el).addClass('icon-maree-haute');
        } else {
            $('.icon-maree', el).classList('icon-maree');
        }

        $('.low,.high,.none', el).hide();
        $('.' + currData['state'], el).show();

    }

};

maree.init();

var calendarDateFormatMaree = 'DD/MM/YYYY';
var siteLanguageMaree = $('html').attr('lang');
var calendarDateLanguageMaree = siteLanguageMaree.substr(0, siteLanguageMaree.indexOf('-'));
var startDate = moment(new Date()).format(calendarDateFormatMaree);

var groupes_maree_input_dates = $('.js-component-maree-group-date');

if (groupes_maree_input_dates) {

    $('input.date', groupes_maree_input_dates).each(function () {
        var container = '#barre-filtres  .facettes-wrapper';
        var inline = false;

        if (!isTouchDevice()) {
            $(this).attr('type', 'text');
            $(this).attr('placeholder', '');
            $(this).dateRangePicker({
                inline: inline,
                singleDate: false,
                alwaysOpen: false,
                singleMonth: false,
                showShortcuts: false,
                showTopbar: false,
                monthSelect: true,
                hoveringTooltip: false,
                selectForward: false,
                yearSelect: [2022, 2030],
                startOfWeek: 'monday',
                startDate: startDate,
                customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
                customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
                format: calendarDateFormatMaree,
                language: calendarDateLanguageMaree,
                container: container,
                separator: ' to ',
                getValue: function () {
                    if ($('[data-jsdatestatus="from"]').val() && $('[data-jsdatestatus="to"]').val())
                        return $('[data-jsdatestatus="from"]').val() + ' to ' + $('[data-jsdatestatus="to"]').val();
                    else
                        return '';
                },
                setValue: function (s, s1, s2) {
                    $('[data-jsdatestatus="from"]').val(s1);
                    $('[data-jsdatestatus="to"]').val(s2);
                },
            }).bind('datepicker-open', function (event, obj) {
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').addClass('focus');
            }).bind('datepicker-first-date-selected', function (event, obj) {
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').removeClass('focus');
                $('[data-jsdatestatus="to"]').parent('.wrapper-date').addClass('focus');
            }).bind('datepicker-close', function (event, obj) {
                $('[data-jsdatestatus="to"]').parent('.wrapper-date').removeClass('focus');
                $('[data-jsdatestatus="from"]').parent('.wrapper-date').removeClass('focus');

                if ($('[data-jsdatestatus="to"]').val() && $('[data-jsdatestatus="from"]').val()) {

                    maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val(), $('[data-jsdatestatus="to"]').val()], function (html) {
                        if (html) {
                            maree_html = $('#component-maree .tbody', html).html();
                            if (maree_html != '') {
                                $('#component-maree .tbody').html(maree_html);
                            }
                        }
                    });


                }
            });
        } else {
            var data_date = $(this).val();
            data_date = data_date.split('/').reverse().join('-');
            $(this).val(data_date);
            $(this).attr('type', 'date');

            $(this).on('change', function (eventObject) {
                var date_status = $(this).data('jsdatestatus');
                $('[data-jsdatestatus="' + date_status + '"]', '#barre-filtres [data-jsgroupdate="' + groupes_input_dates + '"]').val($(this).val());
            })
        }
    });
}

var from_maree_date = $('.js-component-maree-from-date');
if (from_maree_date) {

    $('input.date', from_maree_date).each(function () {

        var container = '.js-wrapper-dates-maree';
        var inline = false;

        if (!isTouchDevice()) {
            $(this).attr('type', 'text');
            $(this).dateRangePicker({
                inline: inline,
                container: container,
                singleDate: true,
                alwaysOpen: false,
                singleMonth: true,
                showShortcuts: false,
                showTopbar: false,
                monthSelect: true,
                hoveringTooltip: false,
                selectForward: false,
                yearSelect: [2022, 2030],
                startOfWeek: 'monday',
                startDate: startDate,
                customArrowPrevSymbol: '<span class="icon-ico-arrow-left"></span>',
                customArrowNextSymbol: '<span class="icon-ico-arrow-right"></span>',
                format: calendarDateFormatMaree,
                language: calendarDateLanguageMaree,
            }).bind('datepicker-close', function (event, obj) {
                if ($('[data-jsdatestatus="from"]').val()) {
                    maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val()], function (html) {
                        if (html) {
                            maree_html = $('.js-component-maree-content', html).html();

                            if (maree_html != '') {
                                $('.js-component-maree-content').html(maree_html);
                            }
                        }
                    });
                }
            });
        } else {
            var data_date = $(this).val();
            data_date = data_date.split('/').reverse().join('-');
            $(this).val(data_date);
            $(this).attr('type', 'date');

            console.log('saucisse');
            $(this).on('change', function (eventObject) {
                var date_status = $(this).data('jsdatestatus');
                $('[data-jsdatestatus="' + date_status + '"]', '#barre-filtres [data-jsgroupdate="' + groupes_input_dates + '"]').val($(this).val());

                maree.getFromTo(['maree', $('[data-jsdatestatus="from"]').val()], function (html) {
                    if (html) {
                        maree_html = $('.js-component-maree-content', html).html();

                        if (maree_html != '') {
                            $('.js-component-maree-content').html(maree_html);
                        }
                    }
                });
            })
        }
    });
}
