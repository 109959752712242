var th_overlay_modified = {
    selector_overlay: '.overlay',
    selector_overlay_shadow: '.shadow-overlay',
    selector_overlay_custom: '.overlays_custom',

    callbackOpen: [],
    callbackClose: [],
    overlayList: [],

    ajaxUrl: 'wp-content/themes/pleneuf/ajax/customOverlay.php',

    preventDefault: true,

    init: function (settings) {
        th_overlay_modified.parselinks();

        if (settings) {
            $.each(settings, function (key, setting) {
                if (th_overlay_modified[key]) {
                    th_overlay_modified[key] = setting;
                }
            });
        }
    },

    addCallbackOpen: function (callback) {
        th_overlay_modified.callbackOpen.push(callback);
    },
    addCallbackClose: function (callback) {
        th_overlay_modified.callbackClose.push(callback);
    },

    parselinks: function(){
        $('a[href^="#"]').each(function(){
            var overlayId = $(this).attr('href').replace('#', '');
            if (overlayId !== ""){
                th_overlay_modified.overlayList.push(overlayId);
            }
        });
        th_overlay_modified.setOverlay();
    },

    setOverlay: function(){

        let thedata = {
            'action': 'handle_ajax_custom_overlay',
            overlayList: th_overlay_modified.overlayList,

        }

        if (typeof _post !== 'undefined'){
            const json = JSON.parse(_post);
            thedata = Object.assign(thedata, json);
        }

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            // action: 'handle_ajax_custom_overlay',
            method: 'POST',
            data: thedata
        }).done(function(data) {
            if (data){
                $(th_overlay_modified.selector_overlay_custom).html(data);

                $(th_overlay_modified.selector_overlay_custom+' .frm_checkbox > label').append('<span></span>');
                $(th_overlay_modified.selector_overlay_custom+' .frm_radio > label').append('<span></span>');

                $(th_overlay_modified.selector_overlay_custom+' select').selectric();
            }

            th_overlay_modified.parseOverlayShadow($('body'));
            th_overlay_modified.parse($('body'));
            th_overlay_modified.openOnLoad();
        });
    },

    parseOverlayShadow: function () {
        $(th_overlay_modified.selector_overlay_shadow).on('click', function (e) {
            e.preventDefault();
            th_overlay_modified.close();
        });
    },

    parse: function (el) {

        $(th_overlay_modified.selector_overlay).each(function () {
            var overlayId = this.id;
            var targetOpen = $('a[href="#' + overlayId + '"]:not(.parsed-overlay):not(.close), [data-overlay-open="' + overlayId + '"]:not(.parsed-overlay)');

            targetOpen.addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-open');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    // ajout de l'option data-overlay-disable-hash (sans valeur) pour désactiver à l'ouverture l'ajout du hash dans l'url
                    var disableHash = $(this).attr('data-overlay-disable-hash');
                    var enableHash = (typeof disableHash !== 'undefined' && disableHash !== false && disableHash !== 'false') ? false : true;

                    th_overlay_modified.open(idOverlay,true,true, enableHash);
                });

            $('a[href="#' + overlayId + '"].close:not(.parsed-overlay), [data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-close');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    if (!idOverlay) {
                        idOverlay = null;
                    }

                    if (th_overlay_modified.preventDefault) {
                        e.preventDefault();
                    }
                    th_overlay_modified.close(idOverlay);
                });
        });
    },

    openOnLoad: function () {
        var current_hash = (window.location.hash).split('#');

        if (current_hash) {
            $.each(current_hash, function (index, overlay_id) {
                if (overlay_id && overlay_id.length) {
                    try {
                        var corresponding_overlay = $("#" + overlay_id);
                        var notOpenOnLoad = corresponding_overlay.attr('not-open-onload');

                        if ($(corresponding_overlay).hasClass("overlay") && notOpenOnLoad !== "true") {
                            return th_overlay_modified.open(overlay_id);
                        }
                    } catch(error) {
                        return false;
                    }
                }
            });
        }
    },

    open: function (overlayId, openShadow, doCallback, enableHash) {

        if (openShadow !== false) {
            openShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }
        if (enableHash !== false) {
            enableHash = true;
        }

        $(th_overlay_modified.selector_overlay + ".open").each(function (e) {
            th_overlay_modified.close($(this).attr('id'), false, true)
        });

        $('#' + overlayId).addClass('open');

        if(enableHash == true) {
            window.location.hash = overlayId;
        }

        if (openShadow == true) {
            $(th_overlay_modified.selector_overlay_shadow).addClass('open');
        }

        if (doCallback == true) {
            $.each(th_overlay_modified.callbackOpen, function (k, callback) {
                callback(overlayId);
            });
        }


        $('.overlay .bloc-form input.frm_date').each(function(){
            dateRangePickerInit(this);
        });

    },

    close: function (overlayId, closeShadow, doCallback) {

        if (closeShadow !== false) {
            closeShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        window.location.hash = " ";
        history.replaceState(null, null, ' ');


        if (overlayId) {
            $('#' + overlayId).removeClass('open');
            if ($(th_overlay_modified.selector_overlay + '.open').length == 0 && closeShadow) {
                $(th_overlay_modified.selector_overlay_shadow).removeClass('open');
            }
        } else {
            $(th_overlay_modified.selector_overlay + '.open').removeClass('open');
            if (closeShadow) {
                $(th_overlay_modified.selector_overlay_shadow).removeClass('open');
            }
        }

        if (doCallback) {
            $.each(th_overlay_modified.callbackClose, function (k, callback) {
                callback(overlayId);
            });
        }

    }

};
