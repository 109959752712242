var ongletsRelationWrapper = $(".bloc-slider-thematique");
ongletsRelationWrapper.each(function(){
    $(this).find('input:first-child').prop( "checked", true );
});
var ongletsRelation = $(".bloc-slider-thematique .container-onglets label");
ongletsRelation.each(function () {
    $(this).on('click', function (e) {
        e.preventDefault();
        var radioId = e.target.getAttribute('for');
        var radio = document.querySelector('#' + radioId);
        console.log(radioId);
        console.log(radio);
        radio.click();
    })
});

(function () {


    var sliderContainers = document.querySelectorAll(".bloc-slider-thematique .th-slider");
    for (i = 0; i != sliderContainers.length; i++) {
        var sliderContainer = sliderContainers[i];
        initSliderThematique(sliderContainer);
    }

    function initSliderThematique(slider) {
        if (slider) {
            this.slider = new thSlider(slider, {
                scrollListener: true,
                draggable: true,
                scrollModeMaxWidth: 1199,
                oninit: function (slider) {}
            });
        }
    }

})();