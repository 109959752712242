var carteInte = {
    _selector: {
        carte: '.carte-inte',
        cards: '[data-card]',
        pois: '[data-poi]',
        slider: '.th-slider',
    },
    slider: null,


    init: function(){
        var sliders = document.querySelectorAll(carteInte._selector.carte+' '+carteInte._selector.slider);
        for (i = 0; i != sliders.length; i++) {
            var slider = sliders[i];
            carteInte.initSlider(slider);
        }

        var firstCard = $(carteInte._selector.cards)[0];
        var firstPoi = $(firstCard).attr('data-card');
        carteInte.setPoi(firstPoi);
        carteInte.listenPoi();
    },

    initSlider: function(slider){
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }

                carteInte.onChangeSlider(slider);
            }
        });
        carteInte.slider = s;
    },


    onChangeSlider: function(slider){
        var items = slider['state']['items'];
        var current = slider['state']['current'];
        var poi = $(items[current]).attr('data-card');
        carteInte.setPoi(poi);
    },

    listenPoi: function(){
        $(carteInte._selector.pois).on('click', function(){
            var poi = $(this).attr('data-poi');
            var card = $('.custom-card[data-card="'+poi+'"]', carteInte._selector.carte);
            var cardIndex = card.attr('data-index');

            carteInte.setCard(cardIndex);
            carteInte.setPoi(poi);
        });
    },

    resetPoi: function(){
        $(carteInte._selector.pois).removeClass('active');
    },

    setPoi: function (poi){
        carteInte.resetPoi();
        $('.carte-inte__carte__pois__poi[data-poi="'+poi+'"]').addClass('active');
    },

    setCard: function (index){
        carteInte.slider.goto(index);

    }
}

carteInte.init();