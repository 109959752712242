(function () {
    $('[data-overlay-open="overlay-search"]').on('click', function () {
        $('#form-search input#search').focus();
    });
})();

var mainNav = {
    _selector: {
        nav: '.nav-primary',
        link: 'a[data-menu-item]',
        subMenu: '.nav-primary__niv2',
        retour: '.nav-primary__niv2__retour'
    },

    init: function () {
        mainNav.listen();
    },

    /**
     * Listenners
     * */
    listen: function () {
        //Ouverture d'un second menu
        var links = document.querySelectorAll(mainNav._selector.link);
        links.forEach(function (link) {
            link.addEventListener('click', function () {
                mainNav.subMenuClick(link);
            });
        });

        //Clic sur le bouton retour (un des boutons retour pour être exact)
        var retours = document.querySelectorAll(mainNav._selector.retour);
        retours.forEach(function (retour) {
            retour.addEventListener('click', function () {
                mainNav.closeAllSubNav();
            });
        });
    },

    /**
     * Évent de click pour l'ouverture du second menu de nav
     * */
    subMenuClick: function (link) {
        console.log('__click');
        var data = link.getAttribute("data-menu-item");

        mainNav.closeAllSubNav();
        mainNav.openSubNav(data);
    },

    /**
     * Remove toutes les classes open des sous menus
     * */
    closeAllSubNav: function () {
        console.log('___close');
        var sousMenus = document.querySelectorAll(mainNav._selector.subMenu);
        sousMenus.forEach(function (sub) {
            sub.classList.remove('open');
        });
    },

    /**
     * Ouvre un sous-menu spécifique
     * */
    openSubNav: function (item) {
        console.log('___open specific');
        document.querySelector(mainNav._selector.subMenu + '[data-menu-item="' + item + '"]').classList.add('open');
    }
}

mainNav.init();