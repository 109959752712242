function initSocialSlider() {
    var sliders = document.querySelectorAll('.strate-footer-social-wall .wrapper .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSliderSocial(slider);
    }

    function initSliderSocial(slider) {
        // console.log("sliders", sliders);
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: 1024
        });

    }
}


function isSliderSocialReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderSocialReady, 1000);
        return false;
    }
    initSocialSlider();
}

isSliderSocialReady();

